<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <div class="d-flex justify-content-between align-items-center">
            <h4 class="page-title">Evaluation Suggestion List Report</h4>
            <div>
              <button
                class="ms-3 btn btn-danger btn-sm"
                @click="exportPdfClick()"
              >
                <span
                  v-if="pdfLoading"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                {{ pdfLoading == true ? "Loading..." : "Export PDF" }}
              </button>
              <button
                class="ms-2 btn btn-success btn-sm"
                @click="exportExcelClick()"
              >
                <span
                  v-if="excelLoading"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                {{ excelLoading == true ? "Loading..." : "Export Excel" }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="d-flex">
              <!-- <div class="d-flex"> -->

              <div>
                <label class="col-form-label ms-1 me-1">Module</label>
                <div style="width: 250px">
                  <v-select
                    label="name"
                    v-model="moduleName"
                    :options="modules"
                    :clearable="false"
                    :selectable="(options) => options.id != moduleName?.id"
                    @option:selected="getFilterData(moduleName, trainer)"
                  >
                  </v-select>
                </div>
              </div>

              <div class="ms-2">
                <label class="col-form-label ms-1 me-1">Trainer Name</label>
                <div style="width: 250px">
                  <v-select
                    label="name"
                    v-model="trainer"
                    :options="employeeLists"
                    :clearable="false"
                    :selectable="(options) => options.id != trainer?.id"
                    @option:selected="getFilterData(moduleName, trainer)"
                  >
                  </v-select>
                </div>
              </div>

              <div class="ms-2">
                <label class="col-form-label ms-1 me-1">Month</label>
                <div style="width: 200px">
                  <v-select
                    label="name"
                    v-model="month"
                    :options="allMonths"
                    :clearable="false"
                    :selectable="(options) => options.id != month?.id"
                    @option:selected="getFilterData(moduleName, trainer, month)"
                  >
                  </v-select>
                </div>
              </div>

              <div @click="refreshData" class="icon-css pt-4">
                <i style="color: #4a81d4" class="fe-rotate-cw"></i>
              </div>
              <!-- </div> -->
            </div>
            <place-holder v-if="loading"></place-holder>
            <div class="table-responsive mt-4" v-if="!loading">
              <table
                class="table table-striped dt-responsive mb-3"
                id="dataTable"
                v-show="!loading"
              >
                <thead v-if="!loading">
                  <tr>
                    <th>No.</th>
                    <th>Module Name</th>
                    <th>Suggestion</th>
                    <th>Trainer</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th class="d-none">Month</th>
                  </tr>
                </thead>

                <tbody>
                  <tr v-for="(item, index) in allEvaluation" :key="index">
                    <td>{{ ++index }}</td>
                    <td>{{ item.module_name }}</td>
                    <td>
                      {{ item.suggestion }}
                    </td>
                    <td>
                      {{
                        employeeLists
                          .filter((emp) => item.trainer.includes(emp.emp_id))
                          .map((trainer) => trainer.name)
                          .join(", ")
                      }}
                    </td>
                    <td>{{ item.start_date }}</td>
                    <td>{{ item.end_date }}</td>
                    <td class="d-none">{{ getMonth(item.start_date) }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "datatables.net/js/jquery.dataTables.min.js";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "datatables.net-bs5/js/dataTables.bootstrap5.min.js";
import "datatables.net-responsive-bs5/css/responsive.bootstrap5.min.css";
import "datatables.net-responsive-bs5/js/responsive.bootstrap5.min.js";
import $ from "jquery";
import axios from "axios";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { useToast } from "vue-toastification";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import badgeSuccess from "../../../components/shared/badgeSuccess.vue";
import { months } from "../../../utlis/months";
import moment from "moment";
import { exportExcel } from "../../../utlis/excel-export-utlis";
import { exportPdf } from "../../../utlis/pdf-export-utlis";
import userHasPermissions from "../../../mixins/userHasPermissions";

export default {
  mixins: [userHasPermissions],
  setup() {
    const toast = useToast();
    return { toast };
  },
  components: {
    vSelect,
    flatPickr,
    badgeSuccess,
  },
  data() {
    return {
      moduleName: "",
      trainer: "",
      month: "",
      allEvaluation: [],
      employeeLists: [],
      modules: [],
      allMonths: [],
      headers: [
        "No",
        "Module Name",
        "Suggestion",
        "Trainer",
        "Start Date",
        "End Date",
      ],
      title: "Evaluation Suggestion Report",
      loading: false,
      pdfLoading: false,
      excelLoading: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
      baseUrlHRIS: process.env.VUE_APP_COURSIA_BASE_URL,
    };
  },
  methods: {
    getMonth(month) {
      return moment(month).format("MMMM");
    },
    async getAllEmployee() {
      this.loading = true;
      await axios.get(`${this.baseUrlHRIS}api/employees`).then((response) => {
        this.employeeLists = response.data.data;
        this.loading = false;
      });
    },
    async getAllModules() {
      this.loading = true;
      this.$Progress.start();
      await axios.get(`${this.baseUrl}admin/v1/allmodules`).then((response) => {
        this.modules = response.data.data;
        this.loading = false;
        this.$Progress.finish();
      });
    },
    getFilterData(moduleName, trainer, month) {
      this.clearFilter();
      $.fn.dataTable.ext.search.push(function (settings, data, dataIndex) {
        let moduleFilter = moduleName?.name;
        let moduleStage = data[1];
        let trainerFilter = trainer?.name;
        let trainerStage = data[3];
        let monthFilter = month?.name;
        let monthStage = data[6];
        if (
          (moduleFilter == moduleStage || !moduleFilter) &&
          (trainerStage.includes(trainerFilter) || !trainerFilter) &&
          (monthFilter == monthStage || !monthFilter)
        ) {
          return true;
        }
        return false;
      });
      $("#dataTable").DataTable().draw();
    },
    async getAllEvaluation() {
      await axios
        .get(`${this.baseUrl}admin/v2/evaulation-suggestion-report`)
        .then((response) => {
          this.loading = true;
          this.$Progress.start();
          this.allEvaluation = response.data.data;
          this.loading = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          console.log(error, "error value is");
        });
      //
      $("#dataTable").DataTable().draw();
    },
    exportPdfClick() {
      const dataArray = $("#dataTable")
        .DataTable()
        .rows({ search: "applied" })
        .data()
        .toArray();
      if (dataArray.length == 0)
        return this.toast.error("There is no data to export!");
      this.pdfLoading = true;
      const sheetData = [];

      let i = 0;
      dataArray.forEach((item) => {
        item[0] = ++i;
        item[item.length - 1] = "";
        sheetData.push(item);
      });
      exportPdf(this.headers, sheetData, this.title);

      this.pdfLoading = false;
    },
    exportExcelClick() {
      const dataArray = $("#dataTable")
        .DataTable()
        .rows({ search: "applied" })
        .data()
        .toArray();
      if (dataArray.length == 0)
        return this.toast.error("There is no data to export!");
      this.excelLoading = true;
      const sheetData = [];

      sheetData.push(this.headers);
      let i = 0;
      dataArray.forEach((item) => {
        item[0] = ++i;
        item[item.length - 1] = "";
        sheetData.push(item);
      });
      exportExcel(sheetData, "Evaluation Suggestion");
      this.excelLoading = false;
    },
    refreshData() {
      this.moduleName = "";
      this.trainer = "";
      this.allEvaluation = [];
      this.clearFilter();
      this.getAllEvaluation();
    },
    clearFilter() {
      $.fn.dataTable.ext.search.pop();
      $("#dataTable").DataTable().draw();
    },
  },
  async created() {
    this.clearFilter();
    this.allMonths = months;
    if (this.$store.getters["odoo/getAllEmployees"].length > 0) {
      this.employeeLists = this.$store.getters["odoo/getAllEmployees"];
    } else {
      await this.getAllEmployee();
    }
    this.getAllModules();
    await this.getAllEvaluation();
  },
};
</script>

<style scoped>
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #4a81d4;
  border-color: #4a81d4;
}
</style>
